// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const APP_START = 'APP_START';

export const API_ENDPOINT =process.env.REACT_APP_API_URL;
export const APP_NAME =process.env.REACT_APP_NAME;
export const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
export const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;
export const NODE_API_ENDPOINT = process.env.REACT_APP_NODE_API_URL;
export const NODE_API_ADDRESS = process.env.REACT_APP_NODE_API_ADDRESS;
export const REACT_APP_ON_BOARDING_API_ADDRESS = process.env.REACT_APP_ON_BOARDING_API_ADDRESS;
export const BETA_API_ENDPOINT =process.env.REACT_APP_BETA_API_URL;
export const BETA_API_ADDRESS = process.env.REACT_APP_BETA_API_ADDRESS;
export const BETA_API_URL = process.env.REACT_APP_API_V2_URL;
export const LENDING_COMPANIES = process.env.REACT_APP_LENDING_COMPANIES;
